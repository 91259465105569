import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import styled from 'styled-components'

import Form from 'ui/src/Form'
import FormField from 'ui/src/FormField'
import FormLabel from 'ui/src/FormLabel'
import Input from 'ui/src/Input'
import Checkbox, { type IProps as CheckboxProps } from 'ui/src/Checkbox/Checkbox'
import Textarea from 'ui/src/Textarea'
import { Dropdown } from 'ui/src/Dropdown'
import Button from 'ui/src/Button'
import PopupCTA from 'ui/src/PopupCTA/popupCTA'
import { PopupContent } from './PopupContent'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'
import * as validation from './validation'
import { useApi } from './api'
import { getDataValue, transformOptionItems } from './utils'
import { above, color, costaDisplayWaveBold, headingOne, baseBodyText } from 'ui/src/styles'
import { ConsentCheckbox } from './types'
import type * as Contentful from 'cw-frontend/src/types/contentful'
import type { ContentfulLink } from 'cw-frontend/src/types/contentful/old'
import { MaybeEmpty, MaybeValue } from 'cw-frontend/src/types/utils'
import { useLink } from 'utils/src/hooks/useLink'

type PickFields<Type extends keyof FormFields> = Pick<FormFields, Type>
type InputFields = PickFields<
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'jobTitle'
  | 'companyName'
  | 'otherIndustry'
  | 'otherProductProposition'
>
type DropdownFields = PickFields<'industry' | 'companySize'>
type MultiCheckboxFields = PickFields<'productProposition'>
type TextAreaFields = PickFields<'additionalComments'>
type CheckboxFields = PickFields<'terms' | 'marketing' | 'partnersMarketing'>
type OptionsArray = { id: string; value: string | number; label: string }[]

export type FormFields = {
  firstName: string
  lastName: string
  email: string
  phone: string
  jobTitle: string
  companyName: string
  industry: number
  otherIndustry?: string
  productProposition?: (number | string)[]
  otherProductProposition?: string
  additionalComments: string
  companySize?: number
  sourceOfLead: number
  leadSubSource: number
  terms: 1 | 0
  marketing: 1 | 0
  partnersMarketing: 1 | 0
  contactUs?: 1 | 0
  countryCode?: MaybeValue<number>
  eventLead: 1 | 0
  gatedFormCompleted: 1 | 0
  postcode: string
}

type IProps = {
  locale?: string
} & MaybeEmpty<{
  anchor: string
  businessContactFormIndustry: Contentful.Data[]
  businessContactFormIndustryOther: Contentful.Data
  businessContactFormProductProposition: Contentful.Data[]
  businessContactFormProductPropositionOther: Contentful.Data
  businessContactFormCompanySize: Contentful.Data[]
  businessContactFormEventLead: boolean
  businessContactFormGatedFormCompleted: boolean
  businessContactFormCountryCode: Contentful.Data
  businessContactFormConsentCheckboxes: string[]
  businessContactFormSuccessLink: Contentful.Link | ContentfulLink
}>

export function BusinessContactForm(props: IProps) {
  const {
    anchor,
    locale,
    businessContactFormIndustry,
    businessContactFormIndustryOther,
    businessContactFormProductProposition,
    businessContactFormProductPropositionOther,
    businessContactFormCompanySize,
    businessContactFormEventLead,
    businessContactFormGatedFormCompleted,
    businessContactFormCountryCode,
    businessContactFormConsentCheckboxes,
    businessContactFormSuccessLink,
  } = props
  const microcopy = getMicrocopyObject(useMicrocopy(locale))
  const otherIndustryValue = getDataValue(businessContactFormIndustryOther)
  const otherProductPropositionValue = getDataValue(businessContactFormProductPropositionOther)
  const successLink = useLink(businessContactFormSuccessLink)
  const { control, formState, handleSubmit, watch, reset } = useForm<FormFields>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      companyName: '',
      industry: 0,
      additionalComments: '',
      sourceOfLead: 2,
      leadSubSource: 261040000,
      terms: 0,
      marketing: 0,
      partnersMarketing: 0,
      contactUs: 1,
      countryCode: businessContactFormCountryCode?.valueNumber,
      eventLead: businessContactFormEventLead ? 1 : 0,
      gatedFormCompleted: businessContactFormGatedFormCompleted ? 1 : 0,
      postcode: 'Unknown',
    },
    resolver: vestResolver<FormFields, unknown>(
      validation.getSuite(microcopy, {
        companySize: Boolean(businessContactFormCompanySize),
        otherIndustryValue,
        otherProductPropositionValue,
      })
    ),
  })
  const api = useApi()
  const industryOptions = transformOptionItems(businessContactFormIndustry)
  const productPropositionOptions = transformOptionItems(businessContactFormProductProposition)
  const companySizeOptions = transformOptionItems(businessContactFormCompanySize)
  const showConsentCheckbox = (name: string) => businessContactFormConsentCheckboxes?.includes(name)

  function renderInput(name: keyof InputFields, options?: { type?: string }) {
    const { type = 'text' } = options ?? {}

    return (
      <FormField wide className="formField">
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field, formState }) => (
            <Input
              {...field}
              id={field.name}
              type={type}
              label={microcopy.businessContactForm[name]}
              placeholder={microcopy.businessContactForm[`${name}Placeholder`]}
              error={formState.errors[name]?.message}
            />
          )}
        />
      </FormField>
    )
  }

  function renderDropdown(name: keyof DropdownFields, options: OptionsArray) {
    return (
      <FormField wide>
        <Controller
          name={name}
          control={control}
          render={({ field, formState }) => {
            return (
              <Dropdown
                {...field}
                id={field.name}
                name={field.name}
                label={microcopy.businessContactForm[name]}
                value={field.value}
                options={options}
                maxVisible={10}
                placeholder={microcopy.businessContactForm[`${name}Placeholder`]}
                error={formState.errors[name]?.message}
              />
            )
          }}
        />
      </FormField>
    )
  }

  function renderMultiCheckbox(name: keyof MultiCheckboxFields, data: OptionsArray) {
    return (
      <MultiCheckboxFormField wide>
        <FormLabel htmlFor={name}>{microcopy.businessContactForm[name]}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              {data.map(item => (
                <Checkbox
                  {...field}
                  key={item.value}
                  id={item.id}
                  value={field.value?.includes(item.value as any) ?? false}
                  onChange={checked => {
                    const fieldValue = field.value ?? []
                    const newValue = checked
                      ? [...fieldValue, item.value]
                      : fieldValue.filter(v => v !== item.value)

                    field.onChange(newValue)
                  }}
                  className="checkbox"
                  size="large"
                  labelTheme="darkMedium"
                >
                  {item.label}
                </Checkbox>
              ))}
            </>
          )}
        />
      </MultiCheckboxFormField>
    )
  }

  function renderTextArea(name: keyof TextAreaFields) {
    return (
      <FormField wide>
        <Controller
          name={name}
          control={control}
          render={({ field, formState }) => (
            <Textarea
              {...field}
              id={field.name}
              label={microcopy.businessContactForm[name]}
              maxLength={1000}
              maxLengthMessage={microcopy.businessContactForm[`${name}MaxLength`]}
              placeholder={microcopy.businessContactForm[`${name}Placeholder`]}
              error={formState.errors[name]?.message}
            />
          )}
        />
      </FormField>
    )
  }

  function renderCheckbox(name: keyof CheckboxFields) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <StyledCheckbox
            {...field}
            id={field.name}
            value={Boolean(field.value)}
            onChange={value => field.onChange(value ? 1 : 0)}
            error={formState.errors[name]?.message}
            className="checkbox"
            size="large"
          >
            {microcopy.businessContactForm[name]}
          </StyledCheckbox>
        )}
      />
    )
  }

  return (
    <>
      <Form id={anchor ?? undefined} onSubmit={handleSubmit(api.submit)}>
        {microcopy.businessContactForm.heading && (
          <Heading>{microcopy.businessContactForm.heading}</Heading>
        )}
        {microcopy.businessContactForm.copy && <Copy>{microcopy.businessContactForm.copy}</Copy>}
        <NameContainer>
          {renderInput('firstName')}
          {renderInput('lastName')}
        </NameContainer>
        {renderInput('email')}
        {renderInput('phone', { type: 'number' })}
        {renderInput('jobTitle')}
        {renderInput('companyName')}
        {companySizeOptions && renderDropdown('companySize', companySizeOptions)}
        {industryOptions && renderDropdown('industry', industryOptions)}
        {otherIndustryValue &&
          watch('industry') === otherIndustryValue &&
          renderInput('otherIndustry')}
        {productPropositionOptions &&
          renderMultiCheckbox('productProposition', productPropositionOptions)}
        {otherProductPropositionValue &&
          watch('productProposition')?.includes(otherProductPropositionValue) &&
          renderInput('otherProductProposition')}
        {renderTextArea('additionalComments')}

        <MultiCheckboxFormField wide>
          {renderCheckbox('terms')}
          {showConsentCheckbox(ConsentCheckbox.Marketing) && renderCheckbox('marketing')}
          {showConsentCheckbox(ConsentCheckbox.MarketingPartners) &&
            renderCheckbox('partnersMarketing')}
        </MultiCheckboxFormField>

        <FormActions wide>
          <Button
            type="submit"
            variant="primary"
            icon="submit"
            disabled={!formState.isValid || api.submitting}
          >
            {microcopy.submitButtonText}
          </Button>
        </FormActions>
      </Form>

      <PopupCTA
        content={<PopupContent {...microcopy.businessContactForm.successPopup} />}
        buttonValue={microcopy.businessContactForm.successPopup.buttonLabel}
        visible={api.success}
        type="button"
        icon="submit"
        click={() => {
          api.reset()
          reset()
          successLink.navigate()
        }}
      />

      <PopupCTA
        content={<PopupContent {...microcopy.businessContactForm.errorPopup} />}
        buttonValue={microcopy.businessContactForm.errorPopup.buttonLabel}
        click={api.reset}
        icon="back"
        visible={api.error}
        type="button"
      />
    </>
  )
}

const Heading = styled.h2`
  ${costaDisplayWaveBold}
  ${headingOne}

  color: ${color.costaRed};
  margin: 0 auto 16px;
  text-align: center;
`

const Copy = styled.p`
  ${baseBodyText}

  color: ${color.greyDarker};
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
`

const NameContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  max-width: 608px;
  margin: 0 auto;

  > .formField {
    width: 100%;
    margin-bottom: 0px;
  }

  ${above.tabletLarge`
    max-width: 560px;
    gap: 28px;
    grid-template-columns: repeat(2, 1fr);
  `}
`

// Override type because of issue with AnyStyledComponent
const StyledCheckbox = styled(Checkbox as (props: CheckboxProps) => React.JSX.Element)`
  a {
    color: ${color.greyDark};

    &:is(:hover, :focus) {
      color: ${color.lightRed};
    }
  }
`

const FormActions = styled(FormField)`
  display: grid;
  justify-content: center;
  margin-bottom: 0px;

  ${above.tabletLarge`
    justify-content: start;
  `}
`

const MultiCheckboxFormField = styled(FormField)`
  .checkbox {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
